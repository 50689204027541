























































































import { Component, Vue } from "vue-property-decorator";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from "@/interfaces";
import { dispatchGetUsers, dispatchCreateUser } from "@/store/admin/actions";

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public firstName: string = "";
  public lastName: string = "";
  public email: string = "";
  public phone: string = "";
  public isActive: boolean = true;
  public isSuperuser: boolean = true;
  public setPassword = false;
  public password1: string = "";
  public password2: string = "";

  public async mounted() {
    this.reset();
  }

  public reset() {
    this.password1 = "";
    this.password2 = "";
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.phone = "";
    this.isActive = true;
    this.isSuperuser = true;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileCreate = {
        first_name: this.firstName,
        last_name: this.lastName,
        phone: this.phone,
        email: this.email,
      };
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = true;
      updatedProfile.is_superuser = this.isSuperuser;
      updatedProfile.password = this.password1;
      await dispatchCreateUser(this.$store, updatedProfile);
      this.$router.push("/main/admin/users");
    }
  }
}
